import React, { useEffect, useState } from "react";
import "./bannerContact.css";
// import iconeContrat from "../../assets/images/iconeContrat.png";
import iconeBulb from "../../assets/images/iconeAmpoule.png";
import iconePhone from "../../assets/images/iconePhoneSouscrire.png";
import { motion, useAnimation } from 'framer-motion';

function BannerContact() {
    const [shouldMoveButtons, setShouldMoveButtons] = useState(false);

    const handleResize = () => {
        const viewportHeight = window.innerHeight;
        setShouldMoveButtons(viewportHeight < 800);
    };
    const containerButtonsControls = useAnimation();
    useEffect(() => {
        // Ajouter un écouteur d'événement pour gérer le redimensionnement de la fenêtre
        window.addEventListener("resize", handleResize);
        // Appeler handleResize initialement pour définir l'état initial en fonction de la hauteur de l'écran
        handleResize();

        // Nettoyage de l'écouteur d'événement lors du démontage du composant
        return () => {
            window.removeEventListener("resize", handleResize);
        };

    }, []);

    useEffect(() => {
        containerButtonsControls.start({ opacity: 1, y: 0 });
    }, [containerButtonsControls]);

    const handlePhoneClick = () => {
        // Redirige vers le numéro de téléphone
        window.location.href = "tel:0189471956";
    };

    const handleSwitchClick = () => {
        // Redirige vers le site internet chezSwitch
        window.open("https://www.chezswitch.fr/forfait-mobile/#/", "_blank");
    };

    return (
        <article id="containerBanner" className="p-0 d-flex justify-content-center row m-0 py-5 mb-5 position-relative w-100 bg-success">
            <section

                className="p-0 py-5 mt-lg-5 w-100 m-0 d-md-flex align-items-center justify-content-center"
            >
                <h1 className="fw-bold text-center m-auto my-5 p-3 d-block rounded-3">
                    Plus qu'un forfait, une connexion à l'innovation.
                </h1>

            </section>
            <motion.section
                id="containerButtons"
                className={`d-flex flex-column align-items-center justify-content-around flex-md-row z-3 ${shouldMoveButtons ? "moveButtons" : ""
                    }`}
                initial={{ opacity: 0, y: 300 }} // Propriétés initiales de l'animation
                animate={containerButtonsControls} // Utilise le contrôle d'animation créé précédemment
                transition={{ type: "spring", damping: 50, stiffness: 200 }} // Effet de ressort pour une animation smooth
            >
                <div
                    onClick={handleSwitchClick}
                    className="containerContact rounded-5 d-flex px-5 py-1 justify-content-center align-items-center"
                >
                    <article>
                        <img src={iconeBulb} alt="icone d'une ampoule" />
                    </article>
                    <p className="fs-4 m-0 p-1 ps-4 fw-bold">
                        Souscrire en ligne
                    </p>
                </div>
                <div
                    onClick={handlePhoneClick}
                    className="containerContact rounded-5 d-flex px-5 py-1 justify-content-center align-items-center"
                >
                    <article className="">
                        <img src={iconePhone} alt="icone d'un téléphone" />
                    </article>

                    <p className="fs-4 ps p-1 ps-4 m-0 fw-bold">
                        Souscrire par téléphone
                    </p>

                </div>
            </motion.section>
        </article>
    );
}

export default BannerContact;