import React from 'react';
import iconeContrat from '../../assets/images/iconeContrat.png';
import iconeReseauxCom from '../../assets/images/iconeReseauxCom.png';
import iconeAssist from '../../assets/images/iconeAssist.png';
import iconeDocuments from '../../assets/images/iconeDocuments.png';
import iconePhone from '../../assets/images/iconePhone.png';
import icone5g from '../../assets/images/icone5g.png';
import "./articleDescription.css"

function ArticleDescription(props) {
    let imageSrc;
    let altText;

    // Switch case pour déterminer l'image en fonction de props.picture
    switch (props.picture) {
        case 'iconeContrat':
            imageSrc = iconeContrat;
            altText = 'Icône Contrat';
            break;
        case 'iconeReseauxCom':
            imageSrc = iconeReseauxCom;
            altText = 'Icône Réseaux Communication';
            break;
        case 'iconeAssist':
            imageSrc = iconeAssist;
            altText = 'Icône Assistance';
            break;
        case 'iconeDocuments':
            imageSrc = iconeDocuments;
            altText = 'Icône Documents';
            break;
        case 'iconePhone':
            imageSrc = iconePhone;
            altText = 'Icône Téléphone';
            break;
        case 'icone5g':
            imageSrc = icone5g;
            altText = 'Icône 5G';
            break;
        default:

    }

    return (
        <article className='col-12 col-md-6 col-lg-4 px-4'>
            <section className='d-flex justify-content-center my-5'>
                {/* Utilisez l'imageSrc dans la balise img et l'altText dans l'attribut alt */}
                <img className='picSize' src={imageSrc} alt={altText} />
            </section>
            <section>
                {/* Affichez le titre et le contenu du composant */}
                <h3 className='fw-bold fs-3 text-center '>
                    {props.title}
                </h3>
                <p className='mt-5 fw-semibold'>
                    {props.children}
                </p>
            </section>
        </article>
    );
}

export default ArticleDescription;
