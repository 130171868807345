import React from 'react'
import './articleHome.css'
import ArticleDescription from '../ArticleDescription/ArticleDescription'
import ButtonForCall from '../ButtonForCall/ButtonForCall'

function ArticleHome() {
    return (
        <article className='container'>
            <section className='mb-5'>
                <h2 className='fw-bold text-center'>
                    Avec la 5G, profitez d'un débit ultra-rapide pour télécharger en un instant vos contenus préférés et jouer en ligne en toute fluidité. La 5G permet également une réactivité sans précédent pour une expérience utilisateur encore plus agréable.
                </h2>
            </section>
            <section className='row my-5'>
                <ArticleDescription
                    picture="iconeContrat"
                    title="La facilité de souscription SwitchCall"
                >
                    En quelques clics seulement, sélectionnez le forfait qui vous correspond et recevez votre carte SIM en un temps record. L'activation s'effectue automatiquement, et voilà, c'est terminé !
                    <br /> 
                    Une assistance aussi efficace que pratique. 
                    <br />
                    <br />
                    SwitchCall s'occupe de la résiliation de votre contrat et du transfert de votre numéro.
                </ArticleDescription>
                <ArticleDescription
                    picture="iconeReseauxCom"
                    title="Pourquoi choisir la qualité du réseau Bouygues Telecom ?"
                >
                   SwitchCall vous propose un forfait mobile haut de gamme avec une couverture exceptionnelle garantie par le réseau Bouygues Telecom. Profitez d'une réception optimale, que vous soyez en ville, à la campagne, à la montagne ou en bord de mer, que ce soit à la maison ou en déplacement grâce à notre réseau mobile 4G, qui couvre 99% de la population française. En prime, notre réseau 5G ultra-rapide et puissant vous offre une expérience utilisateur sans précédent.
                </ArticleDescription>
                <ArticleDescription
                    picture="iconeAssist"
                    title="Une assistance aussi efficace que pratique"
                >
                   Si vous avez des questions à poser ou si vous avez besoin d'assistance, nous sommes là pour vous fournir des réponses et des conseils. Vous pouvez compter sur notre disponibilité, que ce soit via le chat, en personne dans nos boutiques, par téléphone ou en vous connectant avec l'un de nos conseillers personnels.
                </ArticleDescription>
                <ArticleDescription
                    picture="iconeDocuments"
                    title="Documents à fournir lors d'une souscription"
                >
                    Pour souscrire à un forfait SwitchCall, vous devrez fournir certains documents tels que : 
                  <br />
                        - Une pièce d'identité en cours de validité (carte nationale d'identité, passeport, etc.) 
                  <br />
                        - Un justificatif de domicile récent (facture d'électricité, de gaz, d'eau, etc.) 
                  <br />
                        - Un RIB si vous souhaitez mettre en place un prélèvement automatique pour le paiement de votre forfait.
                  
                </ArticleDescription>
                <ArticleDescription
                   picture="iconePhone"
                    title="Forfait sur mesure à votre disposition"
                   
                >
                    Chez SwitchCall, vous avez la chance de découvrir notre offre exceptionnelle : un forfait sur mesure à prix avantageux, comprenant des SMS, MMS et appels illimités. Vous pouvez aisément le personnaliser selon vos besoins spécifiques, vous garantissant ainsi une expérience téléphonique adaptée et abordable.
                </ArticleDescription>
                <ArticleDescription
                   picture="icone5g"
                    title="Comment profiter de la 5G avec SwitchCall ?"
                >
                    Pour profiter de la 5G avec SwitchCall, il est important de vérifier si votre téléphone est compatible avec cette technologie. Si c'est le cas, il vous suffit de souscrire à un forfait mobile 5G. Si vous avez des questions sur la 5G ou si vous souhaitez vérifier la compatibilité de votre téléphone, n'hésitez pas à contacter notre service client.
                </ArticleDescription>
                <ButtonForCall class="buttonSize"/>

            </section>
        </article >
    )
}

export default ArticleHome