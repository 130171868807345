import React, { useEffect } from 'react'
import Header from '../../Partials/Header/Header'
import Footer from '../../Partials/Footer/Footer';
import TitlePage from '../../Components/TitlePage/TitlePage';
import ArticlePolitiqueConfidentialite from '../../Components/ArticlePolitiqueConfidentialite/ArticlePolitiqueConfidentialite';


function PolitiqueConfidentialite() {
    useEffect(() => {
        window.scrollTo(0, 0); // Fait défiler la fenêtre en haut de la page
      }, []);
    
  return (
    <main  className="d-flex flex-column align-items-center vw-100">
    <Header/>
    <TitlePage title="POLITIQUE DE CONFIDENTIALITÉ"/>
    <ArticlePolitiqueConfidentialite/>
    <Footer/>
   </main>
  )
}

export default PolitiqueConfidentialite