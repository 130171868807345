import React from "react";

function ArticlePolitiqueConfidentialite() {
  

  return (
    <article className="container">
      <section className="my-4">
               <p className="mt-4 ms-2 fw-light">
          Opérateur de communications et d'énergie, Chez Switch apporte à ses
          clients le meilleur service possible. Pour vous délivrer nos services,
          nous avons besoin de collecter et traiter des données personnelles
          vous concernant. Nous attachons la plus grande importance à en faire
          un usage responsable et respectueux de votre confiance. Ainsi, nous
          mettons tout en œuvre pour garantir la confidentialité et la sécurité
          de celles-ci. A travers cette politique de confidentialité, Chez
          Switch souhaite vous informer que lorsque vous naviguez sur notre site
          ou sur votre espace client, souscrivez à un contrat d'énergie,
          utilisez notre estimateur, contactez notre service client, Chez Switch
          est amené à traiter des données à caractère personnel.
        </p>
      </section>
      <section>
        <h4>Politique d'utilisation des données personnelles</h4>
        <p className="mt-4 ms-2 fw-light">
          Pour profiter de nos services, vous nous communiquer certaines données
          telles que votre nom, votre adresse postale, votre date et département
          de naissance, votre numéro de téléphone, votre email, vos coordonnées
          bancaires et votre SIREN quand vous êtes un professionnel.
        </p>
      </section>
      <section>
        <h4>Quelles données et pourquoi ?</h4>
        <p className="mt-4 ms-2 fw-light">
          A travers cette politique de confidentialité, Chez Switch vous informe
          sur la façon dont sont traitées vos données et les moyens mis en œuvre
          pour protéger les informations que vous nous confiez.
        </p>
      </section>
      <section>
        <h4>1. Le responsable de traitement</h4>
        <p className="mt-4 ms-2 fw-light">
          Le Responsable de traitement est Chez Switch marque de SAGITERRE -
          société par actions simplifiée au capital de 100 000€ ayant son siège
          2 rue Saint-Hilaire 51100 REIMS
        </p>
      </section>
      <section>
        <h4>2. Le délégué à la protection des données</h4>
        <p className="mt-4 ms-2 fw-light">
          Un délégué à la protection des données a été désigné. Vous pouvez le
          contacter pour toute question ou réclamation à l'adresse suivante :
          dpo@globalinfo.fr
        </p>
      </section>
      <section>
        <h4>3. Le traitement des données</h4>
        <p className="mt-4 ms-2 fw-light">
          Chez Switch s'engage à ne traiter uniquement que les données
          pertinentes et nécessaires à l'exercice de ses missions sauf intérêt
          légitime à procéder à de tels traitements. Chez Switch souhaite
          informer les clients sur les données qu'ils sont susceptibles de
          transmettre directement ou qui seront collectés automatiquement par
          Chez Switch ou des tiers partenaires.
        </p>
      </section>
      <section>
        <h4>3.1 Données transmises directement</h4>
        <p className="mt-4 ms-2 fw-light">
          Données d'identification : nom, prénom, civilité, adresse e-mail,
          adresse postale (de consommation, de facturation), numéro de
          téléphone, et si nécessaire le code APE, SIRET/SIREN. Données
          bancaires : RIB, mandat, modes de paiement. Données liées au logement
          : maison / appartement, résidence principale / secondaire, surface,
          nombre d'occupants, type de chauffage. Données de consommation : PDL,
          PCE, relevé d'index, consommation annuelle de référence, puissance
          souscrite.
        </p>
      </section>
      <section>
        <h4>3.2 Données transmises par des partenaires</h4>
        <p className="mt-4 ms-2 fw-light">
          Les partenaires avec qui nous travaillons : des comparateurs, les
          gestionnaires de réseau peuvent nous transmettre des données
          personnelles vous concernant. Des données issues d'appels ou contacts
          via différents canaux avec notre service client peuvent être
          collectées.
        </p>
      </section>
      <section>
        <h4>4. Liceité du traitement</h4>
        <p className="mt-4 ms-2 fw-light">
          Le traitement de données à caractère personnel n'est licite que s'il
          repose sur une base légale de traitement.
        </p>
      </section>
      <section>
        <h4>4.1 Exécution du contrat</h4>
        <p className="mt-4 ms-2 fw-light">
          Chez Switch traite des données à caractère personnel pour exécuter le
          contrat de fourniture d'énergie et de services. Dans ce cadre, ces
          données seront utilisées pour :
        </p>
        <ul className=" fw-light">
          <li>Fournir de l'énergie et des services</li>
          <li>Créer votre compte et votre espace client</li>
          <li>
            Gérer la relation client (réponse aux questions et réclamations)
          </li>
          <li>Facturer</li>
          <li>Recevoir les paiements et effectuer les remboursements</li>
          <li>Recouvrer les créances</li>
          <li>Assurer un suivi de la consommation</li>
        </ul>
      </section>
      <section>
        <h4>4.2 Obligation légale</h4>
        <p className="mt-4 ms-2 fw-light">
          Le traitement par Chez Switch de vos données peut être nécessaire pour
          respecter une obligation légale. C'est le cas notamment en répondant
          aux réquisitions et demandes de différentes autorités.
        </p>
      </section>
      <section>
        <h4>4.3 Intérêt légitime</h4>
        <p className="mt-4 ms-2 fw-light">
          Le traitement de données personnelles peut être nécessaire aux fins
          des intérêts légitimes poursuivis par Chez Switch. Vos données seront
          alors traitées sans votre consentement préalable dans les cas suivants
          :
        </p>
        <ul className=" fw-light">
          <li>
            Lutte contre la fraude en partenariat avec notre partenaire de
            paiement en ligne sécurisé
          </li>
          <li>Défense de nos intérêts en justice en cas de contentieux</li>
          <li>Gestion des impayés et recouvrement de créances</li>
        </ul>
      </section>
      <section>
        <h4>4.4 Consentement</h4>
        <p className="mt-4 ms-2 fw-light">
          Dans certains cas, le traitement ne sera licite que si vous avez
          consenti au traitement de vos données à caractère personnel. Chez
          Switch vous demandera votre consentement avant de vous envoyer des
          messages marketing, publicitaires et promotionnels type newsletter et
          des informations relatives à l'utilisation de nos services.
        </p>
      </section>
      <section>
        <h4>5. La durée de conservation des données</h4>
        <p className="mt-4 ms-2 fw-light">
          Vos données sont conservées par Chez Switch durant toute la durée
          nécessaire à l'exécution des finalités pour lesquelles elles ont été
          collectées. Les délais de conservation varient selon la nature des
          données, la finalité du traitement et les exigences légales ou
          réglementaires.
        </p>
        <ul className=" fw-light">
          <li>
            Pour les clients : vos données sont conservées 5 ans après la
            résiliation de votre contrat.
          </li>
          <li>
            Pour les prospects : vos données sont conservées 3 ans après le
            dernier contact. Les catégories de Données Personnelles suivantes
            sont susceptibles d'être conservées pour des durées différentes :
            <ul className=" fw-light">
              <li>
                Les données financières (par exemple les paiements,
                remboursements, etc.) sont conservées pour la durée requise par
                les lois applicables en matière fiscale et comptable ;
              </li>
              <li>
                Les données nécessaires en cas de réclamations ou contentieux
                éventuels en lien avec le contrat ;
              </li>
              <li>Les données de connexion/interactions : 1 an ;</li>
              <li>
                Enregistrement appels téléphoniques reçus ou émis : 6 mois ;
              </li>
            </ul>
          </li>
        </ul>
        <p className="mt-4 ms-2 fw-light">
          A l'issue de ces délais, toutes les données sont supprimées ou
          anonymisées.
        </p>
      </section>
      <section>
        <h4>6. Les destinataires des données</h4>
        <p className="mt-4 ms-2 fw-light">
          Nous sommes susceptibles de partager des informations et pour
          différentes finalités avec nos sous-traitants, partenaires
          commerciaux, ainsi qu'à différentes autorités lorsque cela résulte
          d'une obligation légale.
          <br />
          Les destinataires suivants sont amenés à traiter vos données :
        </p>
        <ul className=" fw-light">
          <li>Nos équipes internes pour la gestion de votre contrat</li>
          <li>
            Notre service clientèle pour le traitement des appels téléphoniques,
            des courriers et des messages électroniques
          </li>
          <li>
            Nos partenaires commerciaux et nos sous-traitants auxquels nous
            recourons en matière de services de paiement, (les fournisseurs,
            d'entreprises de recouvrement, avocats, comptables, huissiers…)
          </li>
          <li>Aux organismes de droit privé ou public</li>
        </ul>
        <p className="mt-4 ms-2 fw-light">
          Chez Switch s'assure que ces données continuent de bénéficier d'un
          niveau de protection adéquat et veille à ce que ses partenaires et
          sous-traitants soient à même de garantir la sécurité et la
          confidentialité des données confiées.
        </p>
      </section>
      <section>
        <h4>7. Le transfert des données</h4>
        <p className="mt-4 ms-2 fw-light">
          Chez Switch s'efforce de conserver les données dans l'Union
          Européenne. Toutefois certaines de ces données peuvent être
          transférées dans d'autres pays et hors de l'Union Européenne à
          condition que ces pays disposent d'un niveau de protection adéquat en
          termes de sécurité et de confidentialité. Dans ces cas-là, nous
          faisons en sorte que ce traitement respecte la présente politique de
          confidentialité et que ce traitement soit encadré par les clauses
          contractuelles types de la Commission Européenne, le privacy shield ou
          des BCR.
          <br />
          Nous attachons une grande importance à conserver vos données en
          garantissant leur intégrité et leur confidentialité. Vos données
          personnelles sont partagées avec des tiers sélectionnés par nos soins.
          Cela peut être le cas avec des prestataires comme des centres
          d'appels, des sociétés affiliées et notre réseau diffuseur de nos
          offres.
          <br />
          Nous confions le stockage à un prestataire de confiance, vos données
          sont dans l'Union Européenne ou au Maroc pour gérer votre contrat, vos
          services et/ou vous proposer nos offres. Lorsque nous transférons vos
          données hors de l'Union Européenne, nous vous garantissons le même
          niveau de protection que dans l'ensemble de l'Union Européenne, parce
          que nos prestataires se sont engagés à se conformer aux clauses
          contractuelles types établies par la Commission Européenne.
          <br />
          Dans l'hypothèse où un tiers non autorisé aurait accès à vos données,
          nous vous en informerions et en aviserions également la Commission
          Nationale Informatique et Libertés.
        </p>
      </section>
      <section>
        <h4>8. Droits sur les données</h4>
        <p className="mt-4 ms-2 fw-light">
          Vous disposez de plusieurs droits sur les données vous concernant :
        </p>
        <ul className=" fw-light">
          <li>
            Vous pouvez choisir, à tout moment et gratuitement, de faire usage
            de votre droit d'opposition.
          </li>
          <li>Vous avez un droit d'accès à vos données</li>
          <li>Vous avez un droit de rectification de vos données.</li>
          <li>Vous disposez d'un droit à l'oubli.</li>
          <li>Vous avez un droit à la limitation.</li>
          <li>Vous avez un droit à la portabilité des données.</li>
          <li>
            Enfin, si vous estimez que le traitement de vos données a été
            effectué en violation de la réglementation applicable, vous avez le
            droit d'introduire une réclamation auprès de la Commission Nationale
            Informatique et Libertés.
          </li>
        </ul>
        <p className="mt-4 ms-2 fw-light">
          Vous pouvez exercer vos droits en nous indiquant l'objet précis de
          votre demande par mail, accompagné de la copie d'un titre d'identité.
          Ces documents sont à adresser par mail à l'adresse :
          <a className="ms-1" href="mailto:dpo@globalinfo.fr">dpo@globalinfo.fr</a>. Si vous êtes
          mineurs, ces droits seront exercés par le titulaire de l'autorité
          parentale avant vos 15 ans, vous pourrez exercer directement vos
          droits lorsque vous aurez 15 ans. Vous pouvez former une réclamation
          auprès de la CNIL en vous connectant sur leur site internet 
          <a href="https://www.cnil.fr/" className="mx-1" target="_blank" rel="noreferrer">
             https://www.cnil.fr 
          </a> 
           ou par courrier postal à l'adresse : CNIL - 3 Place de Fontenoy - TSA
          80715 - 75334 PARIS CEDEX 07.
          <br />
          Quand le traitement est fondé sur le consentement (ex : newsletter),
          vous pouvez retirer votre consentement à tout moment.
        </p>
      </section>
      <section>
        <h4>9. Sécurité des données</h4>
        <p className="mt-4 ms-2 fw-light">
          Chez Switch a mis en œuvre des mesures de sécurité techniques et
          organisationnelles afin de garantir un niveau de sécurité adapté au
          risque incluant des mesures destinées à garantir la confidentialité,
          la disponibilité, et l'intégrité des données. A ce titre Chez Switch :
        </p>
        <ul className=" fw-light">
          <li>Limite l'accès aux données aux seules personnes autorisées</li>
          <li>
            A mis en place des systèmes de filtrage et de contrôle sur ses
            réseaux
          </li>
          <li>A mis en place des systèmes de détection d'intrusions</li>
          <li>
            Dispose de systèmes de sauvegarde garantissant la restauration des
            données
          </li>
        </ul>
      </section>
      <section>
        <h4>10. Cookies</h4>
        <p className="mt-4 ms-2 fw-light">
          Vous pouvez consulter notre politique cookies en cliquant ici :
          <a href="https://www.chezswitch.fr/#/politique-de-cookie" target="_blank" rel="noreferrer">
            https://www.chezswitch.fr/#/politique-de-cookie
          </a>
        </p>
      </section>
      <section>
        <h4>

        11. Confidentialité et mot de passe 
        </h4>
        <p className="mt-4 ms-2 fw-light">

        Vous êtes responsables de la
        protection de votre mot de passe. Vous ne devez pas le communiquer
        volontairement à des tiers.
        </p>
      </section>
      <section>
        <h4>

        12. Sites partenaires 
        </h4>
        <p className="mt-4 ms-2 fw-light">

        Notre site et notre espace client peuvent avoir
        des liens vers des sites internet de nos partenaires ou d'autres
        sociétés. Nous ne sommes pas responsables de l'utilisation faite par ces
        sites de vos données personnelles.
        </p>
      </section>
      <section>
        <h4>

        13. Modification de la politique de confidentialité 
        </h4>
        <p className="mt-4 ms-2 fw-light">

        Cette politique sera
        mise à jour par Chez Switch pour respecter les évolutions légales,
        réglementaires ou opérationnelles. Nous vous conseillons de la consulter
        régulièrement.
        </p>
      </section>
    </article>
  );
}

export default ArticlePolitiqueConfidentialite;