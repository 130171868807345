import React, { useEffect} from 'react'
import Header from '../../Partials/Header/Header'
import Footer from '../../Partials/Footer/Footer';
import TitlePage from '../../Components/TitlePage/TitlePage';
import ArticleMentionsLegales from '../../Components/ArticleMentionsLegales/ArticleMentionsLegales';

function MentionsLegales() {
    useEffect(() => {
        window.scrollTo(0, 0); // Fait défiler la fenêtre en haut de la page
      }, []);
  return (
   <main  className="d-flex flex-column align-items-center vw-100">
    <Header/>
    <TitlePage title="MENTIONS LÉGALES"/>
    <ArticleMentionsLegales/>
    <Footer/>
   </main>
  )
}

export default MentionsLegales;