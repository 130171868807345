import React, { useEffect } from 'react'
import   './home.css'
import Footer from '../../Partials/Footer/Footer'
import Header from '../../Partials/Header/Header'
import BannerContact from '../../Components/BannerContact/BannerContact'
import ArticleHome from '../../Components/ArticleHome/ArticleHome'

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0); // Fait défiler la fenêtre en haut de la page
      }, []);
  return (
    <main className="d-flex flex-column align-items-center vw-100 position-relative">
        <Header/>
        <BannerContact/>
        <ArticleHome/>
        <Footer />
            
    </main>
  )
}

export default Home